import React from 'react';
import '../../App.css';
//import Body from '../Body';
//import AboutUs from '../AboutUs';
import Body from '../Body';
function Home() {
    return(
        <>
            <Body/>
            
        </>
    );
}

export default Home;