import React from 'react';
import '../App.css';
import './Donate.css';


function Donate() {
    return(
        <div className='donate-container' >
            <h2>Accepting Donations Soon!</h2>
            
        </div>
    );
}

export default Donate;