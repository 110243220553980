import React from 'react';
import '../App.css';
import './Events.css';


function Events() {
    return(
        <div className='events-container' >
            <e1>Next General Body Meeting: Monday, March 25th </e1>
            
        </div>
    );
}

export default Events;